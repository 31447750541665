<!--
 * @Description: 运维管理系统 设备管理 ETC etcDev
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="etcDev-mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="ETC名称">
              <el-input v-model="searchForm.userId"
                        placeholder="请输入ETC名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.select"
                         placeholder="全部">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row class="topButton">
              <el-button type="primary">查 询</el-button>
              <el-button type="info"
                         style="margin-left:17px">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>ETC管理</span>
        <el-row class="tableTitleButton">
          <el-button type="primary">新 增</el-button>
          <el-button type="text"
                     style="margin-left:17px">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px">导 出</el-button>
          <el-button type="danger"
                     style="margin-left:17px">批量删除</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <!--  <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template> -->
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumnList: [
        {
          prop: 'deviceName',
          label: 'ETC名称',
        },
        {
          prop: 'parkingLot',
          label: '所属停车场',
        },
        {
          prop: 'type',
          label: '类型',
        },
        {
          prop: 'description',
          label: '描述',
        },
      ],
      tableList: {
        list: [
          {
            deviceName: '001',
            parkingLot: 'P1公共停车场',
            type: '特级',
            description: '重点单位',
          },
          {
            deviceName: '003',
            parkingLot: 'P3公共停车场',
            type: '特级',
            description: '重点单位',
          },
          {
            deviceName: '004',
            parkingLot: 'P4公共停车场',
            type: '高级级',
            description: '非重点单位',
          },
          {
            deviceName: '006',
            parkingLot: 'P6公共停车场',
            type: '特级',
            description: '重点单位',
          },
        ],
      },
      searchForm: {},
      total: 10,
      options: [
        { label: '路段一临时停车', value: 1 },
        { label: '路段二临时停车', value: 2 },
      ],
      parkingLotInformation: [
        {
          parkName: '20337689294',
          plateNumber: '渝B231FH',
          joinTime: '031',
          reasonName: '公园路-123-P停车',
          remark: '028001',
          statusName: '王大陆',
          sta: '2020-03-10',
        },
        {
          parkName: '20337689294',
          plateNumber: '渝B231FH',
          joinTime: '031',
          reasonName: '公园路-123-P停车',
          remark: '028001',
          statusName: '王大陆',
          sta: '2020-03-10',
        },
        {
          parkName: '20337689294',
          plateNumber: '渝B231FH',
          joinTime: '031',
          reasonName: '公园路-123-P停车',
          remark: '028001',
          statusName: '王大陆',
          sta: '2020-03-10',
        },
        {
          parkName: '20337689294',
          plateNumber: '渝B231FH',
          joinTime: '031',
          reasonName: '公园路-123-P停车',
          remark: '028001',
          statusName: '王大陆',
          sta: '2020-03-10',
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  //方法集合
  methods: {
    getList () {
      console.warn('获取内容')
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 85px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.etcDev-mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      // padding-left: 91px;
      // margin-top: 16px;
    }
  }
  .el-button--text {
    background: #019fe4;
    height: 32px;
    width: 60px;
    color: white;
    border-radius: 0;
    font-size: 14px;
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>
